<template>
  <div class="aboutus">
    <!--  -->
    <div class="one-bg-box">
      <div class="obb-box">
        <div class="obb-tit">用心服务</div>
        <div class="obb-txt">24小时服务/随时欢迎您来电咨询</div>
        <div class="obb-txt">联系电话:15562544000</div>
      </div>
    </div>
    <!--  -->
    <div class="part-building-box">
      <div class="pbb-tit-en">News Center</div>
      <div class="pbb-tit">
        <div class="pbbt-line"></div>
        <div class="pbbt-tit">新闻中心</div>
      </div>
      <div class="pbb-content">
        <div class="pbbc-top">
          如果您对我们公司的产品感兴趣,欢迎到我司或者我司办
          公出来勘察,您也可以通过在线咨询,微信服务平台
          ,拨打咨询电话等方式与我们联系
        </div>
        <div class="pbbc-bottom">
          <div class="pbbcb-item">
            <img src="../static/wxxiaochengxuimg.jpg" class="pbbcbi-t" />
            <div class="pbbcbi-m">客服热线</div>
            <div class="pbbcbi-b">15562544000</div>
          </div>
          <div class="pbbcb-item">
            <img src="../static/wxxiaochengxuimg.jpg" class="pbbcbi-t" />
            <div class="pbbcbi-m">客服热线</div>
            <div class="pbbcbi-b">15562544000</div>
          </div>
          <div class="pbbcb-item">
            <img src="../static/wxxiaochengxuimg.jpg" class="pbbcbi-t" />
            <div class="pbbcbi-m">客服热线</div>
            <div class="pbbcbi-b">15562544000</div>
          </div>
        </div>
      </div>
    </div>
    <page-bottom></page-bottom>
  </div>
</template>
<script>
import PageBottom from "../components/PageBottom.vue";
export default {
  components: {
    PageBottom,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
}
.aboutus {
  .one-bg-box {
    height: 500px;
    background-image: url("../static/contactusbg.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .obb-box {
      position: absolute;
      left: 150px;
      top: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .obb-tit {
        margin-top: 20px;
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        opacity: 1;
      }
      .obb-txt {
        margin-top: 20px;
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        opacity: 1;
      }
    }
  }

  .part-building-box {
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .pbb-tit-en {
      font-size: 25px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #2e86a9;
      opacity: 1;
    }
    .pbb-tit {
      width: 600px;
      .pbbt-line {
        margin-top: 50px;
        width: 100%;
        border-bottom: 1px solid #000000;
      }
      .pbbt-tit {
        height: 40px;
        text-align: center;
        top: -20px;
        left: 200px;
        position: relative;
        width: 200px;
        background-color: #fff;
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #2e86a9;
        opacity: 1;
      }
    }
    .pbb-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .pbbc-top {
        width: 400px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        text-align: center;
        line-height: 27px;
        color: #333333;
        opacity: 1;
      }
      .pbbc-bottom {
        margin-top: 70px;
        display: flex;
        .pbbcb-item {
          width: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 100px;
          .pbbcbi-t {
            width: 120px;
            height: 120px;
          }
          .pbbcbi-m {
            margin-top: 15px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            text-align: center;

            opacity: 1;
          }
          .pbbcbi-b {
            text-align: center;
            margin-top: 10px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>

